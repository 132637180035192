import { useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PicassoApi, { ProductInfo } from '../../../../Services/PicassoApi';
import { IcebergButton, useLocalStorage } from '@tradesolution/iceberg-ui-react';
import { ProductData } from '../../../../components/ProductTable';
import useDebounce from '../../../../Shared/useDebounce';
import { useSelectedCompanyContext } from '../../../../Shared/SelectedCompanyContext';

interface Props {
  selectedProducts?: ProductData[];
  onSave: (products: ProductData[]) => void;
}

function ProduktModal(props: Props) {

  const  { selectedCompany } = useSelectedCompanyContext();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [q, setQ] = useState('');
  const debouncedQ = useDebounce(q, 250);

  const [products, setProducts] = useState<ProductInfo[]>([]);

  useEffect(() => {
    setSelectedProducts(props.selectedProducts ?? []);
  }, [props.selectedProducts]);

  // flytt valgte produkter hit
  const [selectedProducts, setSelectedProducts] = useState<ProductData[]>(props.selectedProducts ?? []);

  const [showRecentSearches, setShowRecentSearches] = useState(false);
  const [recentSearches, setRecentSearches] = useLocalStorage<string[]>("picasso-recent-searches", []);
  const queryProducts = async (query: string) => {
    if (query.trim()) {
      // Update recent searches with the new query on top
      setRecentSearches((prev) => {
        const updatedSearches = [query, ...prev.filter((item) => item !== query)];
        return updatedSearches.slice(0, 5); // Begrens til 5 nylige søk
      });
    }
      
    const productSearchResponse = await PicassoApi.productSearch.get(query, selectedCompany?.tsCustomerId, selectedCompany?.gln?.toString());
    setProducts(productSearchResponse.items ?? []);
  };
  
  useEffect(() => { 
    if (selectedCompany?.tsCustomerId) {
      queryProducts(debouncedQ);
    }
  }, [debouncedQ, selectedCompany]);

  const handleSavedProducts = () => {
    props.onSave(selectedProducts);
    setShow(false);
  }

  const mapToProductData = (productInfo: ProductInfo): ProductData => {
    return {
        produktnavn: productInfo.versions[0].variantName,
        epdNr: productInfo.identificationNumber,
        basegtins: productInfo.versions[0].basisGtins ?? productInfo.versions[0].gtins,
        unitSmallPreviewImageGuid: productInfo.versions[0].unitSmallPreviewImageGuids.length > 0 ? productInfo.versions[0].unitSmallPreviewImageGuids[0] : undefined,
        angle: undefined
    };
}

  //Funksjon som hånterer valgte produkter
  const selectProduct = (productInfo: ProductInfo) => { 
    if (selectedProducts.map(o => o.epdNr).includes(productInfo.identificationNumber)) {
      setSelectedProducts(selectedProducts.filter(p => p.epdNr !== productInfo.identificationNumber));
    } else {
      setSelectedProducts([...selectedProducts, mapToProductData(productInfo)]);
    } 
  }

  const removeSelectedProduct = (product: ProductData) => {
    setSelectedProducts(selectedProducts.filter(p => p.epdNr !== product.epdNr));
  }

  const handleRecentSearchSelected = (search: string): void => {
    setQ(search);
    setShowRecentSearches(false);
  }

  return (
    <>
      <div className="d-grid">
      <IcebergButton variant="outline-primary" icon='edit' onClick={handleShow}>
        Legg til produkt
      </IcebergButton>
      </div>

      <Modal show={show} onHide={handleClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Velg produkter for {selectedCompany.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*Valgte produkter */}
          <div className="mb-4">
            <h5>Valgte Produkter ({selectedProducts.length})</h5>
            {selectedProducts.length > 0 ? (
              <Table>
                <thead>
                  <tr>
                    <th className='text-center'>Bilde</th>
                    <th>Produktnavn</th>
                    <th>EPD Nr</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedProducts.map((product, index) => (
                    <tr key={`selected-${index}`}>
                      <td className='text-center'>
                        {product.unitSmallPreviewImageGuid && <img height={30} src={`https://mediastore.tradesolution.no/download/preview/${product.unitSmallPreviewImageGuid}`} alt={product.produktnavn} />}
                      </td>
                      <td>{product.produktnavn}</td>
                      <td>{product.epdNr}</td>
                      <td className='text-end'>
                      <IcebergButton variant='outline-secondary' icon='close'  onClick={() => removeSelectedProduct(product)}>
                        Fjern
                      </IcebergButton>                      
                     </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>Ingen produkter er valgt</p>
            )}
          </div>


        <div className="row mb-4">
          <div className="col-6">
            <Button variant="outline-secondary" className="w-100" onClick={handleClose}>
              Close
            </Button>
          </div>
          <div className="col-6">
          <Button variant="primary" className="w-100" onClick={handleSavedProducts}>
            Lagre
          </Button>
          </div>
        </div>

          {/*Søk etter produkter */}
            <Form.Group>
              <Form.Label>Søk</Form.Label>
              <Form.Control type="search" placeholder="Søk EPD Nr eller produktnavn" 
                  value={q} 
                  onChange={e => setQ(e.target.value)}  
                  onFocus={() => setShowRecentSearches(true)} // Vis nylige søk
                  />
                  <div
                    className="dropdown-menu show position-absolute"
                    style={{ width: "100px", display: showRecentSearches ? "block" : "none" }}
                  >
                    {recentSearches.map((search, index) => (
                      <button
                        key={index}
                        type="button"
                        className="dropdown-item"
                        onClick={() => handleRecentSearchSelected(search)}
                      >
                        {search}
                      </button>
                    ))}
                  </div>
            </Form.Group>
          <Table>
            <thead>
              <tr>
                <th className='text-center'>Bilde</th>
                <th>Produktnavn</th>
                <th>EPD Nr</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {products.map((product, index) => (
              <tr key={product.identificationNumber}>
                <td className='text-center'>
                {product.versions[0].unitSmallPreviewImageGuids[0] && <img height={30} src={`https://mediastore.tradesolution.no/download/preview/${product.versions[0].unitSmallPreviewImageGuids[0]}`} alt={product.versions[0].variantName} />}
                </td>
                <td>{product.versions[0].variantName}</td>
                <td>{product.identificationNumber}</td>
                <td className='text-end'>
                <IcebergButton
                  variant={selectedProducts.map(o => o.epdNr).includes(product.identificationNumber) ? 'outline-secondary' : 'outline-primary'}
                  icon={selectedProducts.map(o => o.epdNr).includes(product.identificationNumber) ? 'check' : 'plus'}
                  onClick={() => selectProduct(product)}  
                >
                  {selectedProducts.map(o => o.epdNr).includes(product.identificationNumber) ? 'Valgt' : 'Velg'}
              </IcebergButton>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
}


export default ProduktModal;