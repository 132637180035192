import './TemplateBaseStyling.scss';
import ProductImage from "./components/ProductImage";
import Logo from "./components/Logo";
import { TemplateData } from "../TemplateData";
import ProductTable, { ProductData } from "../../../components/ProductTable";
import { generateBarcode } from "../../../Shared/BarcodeGenerator";

interface Props {
    templateData: TemplateData
    templateId: number | undefined
}

const Template1 = (props: Props) => {

    const defaultTableData: ProductData[] = [{ produktnavn: "Produkt 1", epdNr: "123456", basegtins: [], strekkodeBase64Url: generateBarcode('123456'), unitSmallPreviewImageGuid: undefined, angle: undefined }];
    const fontFamily = props.templateData.designData.fontFamily ? props.templateData.designData.fontFamily : "inherit";

    return (

        <div
            id="template"
            className="template-container"
            style={{
                color: props.templateData.designData.fontColor ?? "#000",
                backgroundColor: props.templateData.designData.backgroundColor ?? "#fff",
                fontFamily: fontFamily,
                backgroundImage: props.templateData.designData.backgroundImageUrl ? `url(${props.templateData.designData.backgroundImageUrl})` : undefined,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative'
            }}>

            <div className='dynamic-area'>
                <Logo
                    fontFamily={fontFamily}
                    fontColor={props.templateData.designData.fontColor ?? "#000"}
                    logoText={props.templateData.designData.logoText}
                    logoFileName={props.templateData.designData.logoFileName}
                    fontSize={props.templateData.designData.logoFontSize ?? 16}/>

                {props.templateData.designData.title &&
                    <div className="section mt-3">
                        <h2 style={{ fontFamily: fontFamily, color: props.templateData.designData.fontColor ?? "#000", fontSize: props.templateData.designData.titleFontSize ?? 24 }}>{props.templateData.designData.title}</h2>
                    </div>
                }

                <div className="section mt-3 product-image-section">
                    {/* Template 1 */}
                    {(!props.templateId || props.templateId === 1) &&
                        <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].angle : null} heightInPx={500} widthInPx={500} gtin={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].basegtins[0] : null} />
                    }

                    {/* Template 2 */}
                    {props.templateId === 2 &&
                        <>
                            <div className="image-row" style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                                <div className="col" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].angle : null} heightInPx={250} widthInPx={250} gtin={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].basegtins[0] : null} />
                                </div>
                                <div className="col" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].angle : null} heightInPx={250} widthInPx={250} gtin={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].basegtins[0] : null} />
                                </div>
                            </div>
                            <div className="image-center mt-3" style={{ display: "flex", justifyContent: "center" }}>
                                <div className="col" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 2 ? props.templateData.productData[2].angle : null} heightInPx={250} widthInPx={250} gtin={props.templateData.productData && props.templateData.productData.length > 2 ? props.templateData.productData[2].basegtins[0] : null} />
                                </div>
                            </div>
                        </>
                    }

                    {/* Template 3 */}
                    {props.templateId === 3 &&
                        <div className="image-grid" style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", justifyContent: "center" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].angle : null} heightInPx={245} widthInPx={245} gtin={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].basegtins[0] : null} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].angle : null} heightInPx={245} widthInPx={245} gtin={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].basegtins[0] : null} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 2 ? props.templateData.productData[2].angle : null} heightInPx={245} widthInPx={245} gtin={props.templateData.productData && props.templateData.productData.length > 2 ? props.templateData.productData[2].basegtins[0] : null} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 3 ? props.templateData.productData[3].angle : null} heightInPx={245} widthInPx={245} gtin={props.templateData.productData && props.templateData.productData.length > 3 ? props.templateData.productData[3].basegtins[0] : null} />
                            </div>
                        </div>
                    }

                    {/* Template 4 */}
                    {props.templateId === 4 &&
                        <div className="row">
                            <div className="col mr-1" style={{ display: "flex", alignItems: "center", justifyContent: "center", marginRight: "5px" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].angle : null} heightInPx={500} widthInPx={250} gtin={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].basegtins[0] : null} />
                            </div>
                            <div className="col" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].angle : null} heightInPx={500} widthInPx={250} gtin={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].basegtins[0] : null} />
                            </div>
                        </div>
                    }

                    {/* Template 5 */}
                    {props.templateId === 5 &&
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "10px" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].angle : null} heightInPx={250} widthInPx={500} gtin={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].basegtins[0] : null} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].angle : null} heightInPx={250} widthInPx={500} gtin={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].basegtins[0] : null} />
                            </div>
                        </div>
                    }

                    {/* Template 6 */}
                    {props.templateId === 6 &&
                        <div style={{ position: "relative", margin: "0 auto", width: "500px", height: "500px" }}>
                            <div style={{ position: "absolute", top: "0", left: "0", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].angle : null} heightInPx={350} widthInPx={350} gtin={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].basegtins[0] : null} />
                            </div>
                            <div style={{ position: "absolute", top: "150px", left: "150px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].angle : null} heightInPx={350} widthInPx={350} gtin={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].basegtins[0] : null} />
                            </div>
                        </div>
                    }

                    {/* Template 7 */}
                    {props.templateId === 7 &&
                        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].angle : null} heightInPx={500} widthInPx={150} gtin={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].basegtins[0] : null} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].angle : null} heightInPx={500} widthInPx={150} gtin={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].basegtins[0] : null} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 2 ? props.templateData.productData[2].angle : null} heightInPx={500} widthInPx={150} gtin={props.templateData.productData && props.templateData.productData.length > 2 ? props.templateData.productData[2].basegtins[0] : null} />
                            </div>
                        </div>
                    }

                    {/* Template 8 */}
                    {props.templateId === 8 &&
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].angle : null} heightInPx={150} widthInPx={300} gtin={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].basegtins[0] : null} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].angle : null} heightInPx={150} widthInPx={300} gtin={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].basegtins[0] : null} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 2 ? props.templateData.productData[2].angle : null} heightInPx={150} widthInPx={300} gtin={props.templateData.productData && props.templateData.productData.length > 2 ? props.templateData.productData[2].basegtins[0] : null} />
                            </div>
                        </div>
                    }

                    {/* Template 9 */}
                    {props.templateId === 9 &&
                        <div style={{ position: "relative", width: "500px", height: "500px", margin: "0 auto" }}>
                            <div style={{ position: "absolute", top: "0", left: "0", width: "250px", height: "250px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].angle : null} heightInPx={250} widthInPx={250} gtin={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].basegtins[0] : null} />
                            </div>
                            <div style={{ position: "absolute", top: "125px", left: "125px", display: "flex", alignItems: "center", justifyContent: "center", zIndex: "1" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].angle : null} heightInPx={250} widthInPx={250} gtin={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].basegtins[0] : null} />
                            </div>
                            <div style={{ position: "absolute", top: "250px", left: "250px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 2 ? props.templateData.productData[2].angle : null} heightInPx={250} widthInPx={250} gtin={props.templateData.productData && props.templateData.productData.length > 2 ? props.templateData.productData[2].basegtins[0] : null} />
                            </div>
                        </div>
                    }

                    {/* Template 10 */}
                    {props.templateId === 10 &&
                        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].angle : null} heightInPx={500} widthInPx={150} gtin={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].basegtins[0] : null} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].angle : null} heightInPx={500} widthInPx={150} gtin={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].basegtins[0] : null} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 2 ? props.templateData.productData[2].angle : null} heightInPx={500} widthInPx={150} gtin={props.templateData.productData && props.templateData.productData.length > 2 ? props.templateData.productData[2].basegtins[0] : null} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 3 ? props.templateData.productData[3].angle : null} heightInPx={500} widthInPx={150} gtin={props.templateData.productData && props.templateData.productData.length > 3 ? props.templateData.productData[3].basegtins[0] : null} />
                            </div>
                        </div>
                    }

                    {/* Template 11 */}
                    {props.templateId === 11 &&
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].angle : null} heightInPx={100} widthInPx={300} gtin={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData[0].basegtins[0] : null} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].angle : null} heightInPx={100} widthInPx={300} gtin={props.templateData.productData && props.templateData.productData.length > 1 ? props.templateData.productData[1].basegtins[0] : null} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 2 ? props.templateData.productData[1].angle : null} heightInPx={100} widthInPx={300} gtin={props.templateData.productData && props.templateData.productData.length > 2 ? props.templateData.productData[2].basegtins[0] : null} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <ProductImage angle={props.templateData.productData && props.templateData.productData.length > 3 ? props.templateData.productData[3].angle : null} heightInPx={100} widthInPx={300} gtin={props.templateData.productData && props.templateData.productData.length > 3 ? props.templateData.productData[3].basegtins[0] : null} />
                            </div>
                        </div>
                    }
                    {/* Template 12 */}
                    {props.templateId === 12 &&
                        <div style={{ height: '500px' }}></div>
                    }

                </div>

                <div className="section mt-3 product-table-section">
                    <ProductTable tableData={props.templateData.productData && props.templateData.productData.length > 0 ? props.templateData.productData : defaultTableData}></ProductTable>
                </div>
            </div>

            <div className="section pt-3 contact-info-section">
                {props.templateData.contactInfo.name && <div className="text-center">
                    {props.templateData.contactInfo.name}
                </div>}
                {props.templateData.contactInfo.email && <div className="text-center">
                    {props.templateData.contactInfo.email}
                </div>}
                {props.templateData.contactInfo.phone && <div className="text-center">
                    {props.templateData.contactInfo.phone}
                </div>}
            </div>
        </div>
    );
}

export default Template1;
