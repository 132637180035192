import {
	Fragment, ReactNode, useEffect, useState,
} from 'react';
// import { SearchOutlined, CloseIconRounded } from '../Icons'
import './index.scss';
import cx from 'classnames';
import { IcebergButton, IcebergIcon } from '@tradesolution/iceberg-ui-react';

export interface Props {
  options: ICustomer[];
  isTradesolutionUser: boolean;
  selectedCustomerTsKundeId: string | undefined;
  onSelectedChange: (selected: ICustomer) => void;
  onQueryChange: (query: string) => void;
}
export interface ICustomer {
  tsKundeId: string;
  tsKundeNavn: string;
  /** A intrinsic value for differentiating between customers */
  differentiator?: string;
}

export const CustomerGlnSwitcher = ({
	onSelectedChange,
	onQueryChange,
	isTradesolutionUser,
	selectedCustomerTsKundeId,
	options,
}: Props) => {
	const [companies, setCompanies] = useState<ICustomer[]>([]);
	const [input, setInput] = useState<string>('');

	const handleSelectedChange = (selected: ICustomer) => {
		if (selected) {
			onSelectedChange(selected);
		}
	};

	const defaultHighlight = (input: ReactNode[]) => <em>{input}</em>;
	const escapeRegex = (value: string) => value.replace(/[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

	const Highlight = ({
		source, target, children,
	}: any) =>
		highlightWord(source, target, children);

	const highlightWord = (source: any, target: string, callback: any) => {
		const res: ReactNode[] = [];

		if (!source) return res;
		if (!target) return source;

		const regex = new RegExp(escapeRegex(target), 'gi');

		let lastOffset = 0;

		// Uses replace callback, but not its return value
		source.replace(regex, (val: any, offset: any) => {
			// Push both the last part of the string, and the new part with the highlight
			res.push(
				source.substr(lastOffset, offset - lastOffset),
				// Replace the string with JSX or anything.
				(callback || defaultHighlight)(val)
			);
			lastOffset = offset + val.length;
		});

		// Push the last non-highlighted string
		res.push(source.substr(lastOffset));
		return res;
	};

	const sortCustomers = (co: ICustomer[], searchTerm: string) => {
		const copy = [...co];
		const txt = searchTerm.toUpperCase();
		const uniqueCustomers: ICustomer[] = [];
		Array.from(new Set(copy.map(x => x.tsKundeId))).map(tsKundeId => { // make Set of all object ids, dupes will be ignored
			if (copy.find(y => y.tsKundeId === tsKundeId)) {
				let found = copy.find(y => y.tsKundeId === tsKundeId);
				if (found) {
					uniqueCustomers.push(found); // find object with corresponding id in the mergedEnheterList again.
				}
				// returns the list without dupe objects
			}
		});

		if (uniqueCustomers && uniqueCustomers.length > 0) {
			const filtered = uniqueCustomers?.filter(x =>
				((x.tsKundeNavn.toUpperCase().includes(txt)) || (x.differentiator && x.differentiator.toUpperCase().includes(txt)))
			);

			if ((!input) && (filtered) && (filtered.length > 0)) {
				filtered.sort((a, b) => (a.tsKundeNavn.localeCompare(b.tsKundeNavn)));
				let tsIndex = uniqueCustomers.findIndex(
					(x) => x.tsKundeNavn.toUpperCase() === 'TRADESOLUTION AS'
				);

				if (tsIndex > 0) {
					filtered.unshift(uniqueCustomers.splice(tsIndex, 1)[0]);
				}
			}
			return filtered.splice(0, 10);
		}
	};

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			let sorted = sortCustomers(options, input);
			setCompanies(sorted || []);
		}
		return () => {
			mounted = false;
		};
	}, [options]);

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			onQueryChange(input);
			let sorted = sortCustomers(options, input);
			setCompanies(sorted || []);
		}
		return () => {
			mounted = false;
		};
	}, [input]);


	return (
		<Fragment>
			{isTradesolutionUser && (
				<div className="inputContainer">
					<div className="input">
						<IcebergIcon icon="search" className="inputIcon" />
						<input
							type="text"
							// eslint-disable-next-line jsx-a11y/no-autofocus
							autoFocus
							className="inputElement"
							value={input}
							placeholder="Søk etter..."
							onChange={(e) => setInput(e.target.value)}
						/>
						{input.length > 0 && (
							<IcebergButton
								icon="close"
								onClick={() => setInput('')}
								className="clearInputIcon"
								variant="transparent"
							/>
						)}
					</div>
					{((input) && (!companies || companies?.length < 1)) && (
						<div style={{
							marginTop: '.3em',
						}}>
							<span className="noResultTxt">Ingen treff</span>
						</div>
					)}
				</div>
			)}
			{companies && companies.length > 0 && (
				<ul className="optionsList">
					{companies.map((company: ICustomer) => (
						// eslint-disable-next-line jsx-a11y/click-events-have-key-events
						<li
							// eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
							role="button"
							key={Math.random()}
							className={cx("optionsListItem", {
								["active"]: selectedCustomerTsKundeId && company?.tsKundeId === selectedCustomerTsKundeId,
							})}
							onClick={() => handleSelectedChange(company)}
						>
							<div>
								<Highlight source={company?.tsKundeNavn} target={input}>
									{(x: string) =>
										<span
											key={`name-highlight-${company?.tsKundeId}-${Math.random()}`}
											className="highlight"
										>
											{x}
										</span>
									}
								</Highlight>
							</div>
							{company.differentiator && (
								<small>
									<Highlight source={company.differentiator} target={input}>
										{(z: string) =>
											<span key={`differentiator-highlight-${company?.tsKundeId}-${Math.random()}`} className="highlight">
												{z}
											</span>}
									</Highlight>
								</small>
							)}
						</li>
					))}
				</ul>
			)}
		</Fragment>
	);
};