import { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import PicassoApi, { BackgroundImageDto, LogoDto } from '../../../../Services/PicassoApi';
import { IcebergButton } from '@tradesolution/iceberg-ui-react';
import FileUploader from '../FileUploader';
import ConfirmButton from '../../../../components/ConfirmButton';
import LogRocket from 'logrocket';
import { useSelectedCompanyContext } from '../../../../Shared/SelectedCompanyContext';


interface Props {  
  onSave: (url?: string) => void;
  selectedUrl?: string
}

function BackgroundImageModal(props: Props) {

  const { selectedCompany } = useSelectedCompanyContext();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [backgroundImages, setBackgroundImages] = useState<BackgroundImageDto[]>([]);
  const [urls, setUrls] = useState<{ [key: string]: string }>({});  
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState<BackgroundImageDto>();

  const getBackgroundImages = async () => {
    if (!selectedCompany?.tsCustomerId) {
      return;
    }
    const response = await PicassoApi.bacgroundImages.get(selectedCompany.tsCustomerId);
    setBackgroundImages(response ?? []);
  };
  
  const getBackgroundImageAsThumbnail = async (fileName: string) => {
    if (!selectedCompany?.tsCustomerId) {
      return;
    }
    const blob = await PicassoApi.bacgroundImages.getBackgroundIamgeAsThumbnail(fileName, selectedCompany.tsCustomerId);
    if(blob){
      const url = URL.createObjectURL(blob);
      setUrls(prevState => ({ ...prevState, [fileName]: url }));
    }    
  };

  const getBackgroundImage = async (fileName: string) : Promise<string | undefined> => {
    if (!selectedCompany?.tsCustomerId) {
      return;
    }
    const blob = await PicassoApi.bacgroundImages.getBackgroundIamge(fileName, selectedCompany.tsCustomerId);
    if(blob){
      const url = URL.createObjectURL(blob);
      return url;
    }
    return undefined;
     
  };

  useEffect(() => { 
    getBackgroundImages();
  }, []);

  useEffect(() => {
    backgroundImages.forEach(image => {
      getBackgroundImageAsThumbnail(image.fileName);
      if(props.selectedUrl && props.selectedUrl === urls[image.fileName]){
        setSelectedBackgroundImage(image);
      }
    });
  }, [backgroundImages]);
  
  useEffect(() => {    
    setSelectedBackgroundImage(undefined);
    getBackgroundImages();
}, [selectedCompany]);

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const handleSavedBackgroundImage = async () => {    
    setIsDownloading(true);
    if(selectedBackgroundImage){
      var url = await getBackgroundImage(selectedBackgroundImage.fileName);
      props.onSave(url);      
    }
    else{
      props.onSave(undefined);
    }
    setIsDownloading(false);
    setShow(false);
  }
  
  const toggleSelection = (image: BackgroundImageDto) => { 
    if(selectedBackgroundImage?.fileName === image.fileName) {
      setSelectedBackgroundImage(undefined);
      return;
    }
    setSelectedBackgroundImage(image);
  }

  const deleteImage = async (image: LogoDto) => {
    if (!selectedCompany?.tsCustomerId) {
      return;
    }
    await PicassoApi.bacgroundImages.delete(image.fileName, selectedCompany?.tsCustomerId);
    await getBackgroundImages();
    LogRocket.track('Delete backgroundimage', {
      tsKundeNavn: selectedCompany.name,
      fileName: image.fileName
  });
  };
  
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isImageUploaded, setIsImageUploaded] = useState<boolean>(false);

  const handleUpload = async (file: File): Promise<void> => {
    setIsUploading(true);  
    setIsImageUploaded(false);
    await PicassoApi.bacgroundImages.post(file, selectedCompany?.tsCustomerId);
    setIsImageUploaded(true);    
    await getBackgroundImages();
    setIsUploading(false);
    LogRocket.track('Upload backgroundimage', {
      tsKundeNavn: selectedCompany.name,
      fileName: file.name
    });
  };

  return (
    <>
      <div className="d-grid">
      <IcebergButton variant="outline-primary" icon='edit' onClick={handleShow}>
        Legg til bakgrunnsbilde
      </IcebergButton>
      </div>

      <Modal show={show} onHide={handleClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Velg bakgrunsbilde</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="row mb-4">
            <Col className='col-8'>
            <p>Her kan du laste opp bakgrunnsbilder og velge det du ønsker. Bruk unike navn for å unngå overskriving</p>            
            </Col>
            <Col className='text-end'>              
              <FileUploader
                  handleFile={(file: File) => handleUpload(file)}
                  isImageUploaded={isImageUploaded}
                  uploading={isUploading}
                  title={'Last opp bakgrunnsbilde'}
                />        
            </Col>            
          </Row> 
          <Table>
            <thead>
              <tr>
                <th className='text-center'>Bilde</th>
                <th>Filnavn</th>                
                <th></th>
              </tr>
            </thead>
            <tbody>
            {backgroundImages.map((image, index) => (
              <tr key={index}>
                <td className='text-center'>
                  <img height={30} src={urls[image.fileName]} alt={image.fileName} />
                </td>
                <td>{image.fileName}</td>                
                <td className='text-end'>
                  <div className="d-flex justify-content-end">
                    <IcebergButton
                      variant={selectedBackgroundImage?.fileName === image.fileName ? 'outline-secondary' : 'outline-primary'}
                      icon={selectedBackgroundImage?.fileName === image.fileName ? 'check' : 'plus'}
                      onClick={() => toggleSelection(image)}
                      className="me-2"  
                    >
                      {selectedBackgroundImage?.fileName === image.fileName ? 'Valgt' : 'Velg'}
                    </IcebergButton>
                    <ConfirmButton
                      variant='outline-danger'                      
                      onClick={() => deleteImage(image)}
                    >                      
                    </ConfirmButton>
                  </div>
                </td>                
              </tr>
            ))}
            </tbody>
          </Table>
          <div className="row mb-4">
            <div className="col-6">
              <IcebergButton icon='close' variant="outline-primary" className="w-100" onClick={handleClose}>
                Close
              </IcebergButton>
            </div>
            <div className="col-6">
            <IcebergButton variant="primary" icon='disk' isLoading={isDownloading} className="w-100" onClick={handleSavedBackgroundImage}>
              Lagre
            </IcebergButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BackgroundImageModal;