import { useState } from 'react';
import './index.scss';
import { Button, Card, Carousel, Col, Container, Row, Image } from 'react-bootstrap';

const LandingPage = () => {
    {/* Carousel Images */ }
    const images = [
        { src: "/img/CustomDesignHeader.png", alt: "Slide 1" },
        { src: "/img/CustomDesignHeader.png", alt: "Slide 2" },
        { src: "/img/CustomDesignHeader.png", alt: "Slide 3" },
    ];

    const cards = [
        { id: 1, src: "/img/TemplatePreview1.png", title: "Card 1" },
        { id: 2, src: "/img/TemplatePreview2.png", title: "Card 2" },
        { id: 3, src: "/img/TemplatePreview3.png", title: "Card 3" },
        { id: 4, src: "/img/TemplatePreview4.png", title: "Card 4" },
        { id: 5, src: "/img/TemplatePreview5.png", title: "Card 5" },
    ]

    return (

        <div className="container">
            {/* Header Carousel */}
            <Carousel>
                {images.map((image, index) => (
                    <Carousel.Item key={index}>
                        <img
                            className="d-block w-100"
                            src={image.src}
                            alt={image.alt}
                        />
                        <Carousel.Caption>
                            <h3>{`Slide ${index + 1}`}</h3>
                            <p>{`Description for slide ${index + 1}`}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>

            {/* Text and Image Section */}
            <Container className="mt-4">
                {/* Heading Section */}
                <div className="text-front mb-4">
                    <h2>Tender cutlass arrgh seas cup bilged prey bucko corsair.</h2>
                    <p>
                        Amet at cras diam viverra eget sapien pulvinar aliquam. Amet mi volutpat nunc aliquam in. Morbi ultrices netus amet at dolor. Donec purus adipiscing et tellus.
                        Cursus sit volutpat ultrices tellus at. Amet fermentum feugiat vulputate congue at nibh. Auctor elementum nunc aliquet vitae magna egestas nunc.
                        Suspendisse nunc turpis eget nisl lacus nibh tellus amet lorem. Vel ac feugiat arcu donec mauris aliquet nulla est. Mi arcu mi egestas facilisis.
                    </p>
                </div>

                {/* First Row */}
                <Row className="align-items-center">
                    <Col md={6}>
                        <Image src="/img/DesignerPage.png" fluid={true} alt="Box 1" />
                    </Col>
                    <Col md={6}>
                        <h2>Pinnace dead pink me deck mutiny. Sail line yer execution chain pounders.</h2>
                        <p>
                            Hail-shot bilged pinnace jennys gabion piracy dead splice. Rum yawl pink spanker no sails. Locker rig main fluke lateen driver grog. Hail-shot execution sink poop gangplank tender aye cat.
                            Pin corsair no hail-shot spot. A ballast bounty measured gun fleet line crow's tea. Blimey nipper chains gabion davy road ipsum driver.
                            Bilge me davy fer locker american gangway boat. Privateer smartly piracy yellow reef measured corsair warp keelhaul sheet. Sails ballast on aye bow tales.

                            Main men quarterdeck bilge down line spirits. Arrgh line chains ipsum fluke bilge lubber tales corsair furl. Blimey timbers pirate clipper grog american pirate log. Sink yawl tails keelhaul bilge.
                            Heave salmagundi rig pounders gun fleet american sail plate spanish.
                        </p>
                    </Col>
                </Row>

                {/* Second Row */}
                <Row className="align-items-center mt-4">
                    <Col md={6}>
                        <h2>Davy lateen red furl pillage chandler ensign cat poop. Or piracy blow scurvy pay halter gangplank.</h2>
                        <p>
                            Jones' driver jolly dock spirits plate pink. To a fer spyglass clipper grog. Aye american jones' davy no poop. Gunwalls a ipsum yer yer sails pink fathom lass yawl.
                            Cog hang spirits hearties gaff deck guns blimey heave. Spanish hearties bilged topgallant scourge topmast. Blossom topgallant coffer timbers swab. Crack scurvy cutlass yarr log fleet.
                            Nipperkin chandler pinnace buccaneer or grapple aft keelhaul pounders sheet.
                            Coxswain smartly shiver cutlass cog pink just tales yarr.

                            Fluke grog bucko deck furl crack crack main spyglass. Grapple privateer seven pillage scourge cat furl. Crow's crack bucko brethren bilge keelhaul main.
                            Pirate jones' nipperkin bilge salmagundi gaff hands to hang boat. Gaff quarterdeck killick nipperkin yawl avast.
                        </p>
                    </Col>
                    <Col md={6}>
                        <Image src="/img/MakeItPop.png" fluid alt="Box 4" style={{ maxHeight: "600px" }} />
                    </Col>
                </Row>
            </Container>

            {/* Cards Section */}
            <Row className="mt-5 g-1" style={{ justifyContent: 'space-between' }}
            >
                {cards.map((card) => (
                    <Col
                        key={card.id}
                        lg={2} // On large screens: 6 cards per row
                        md={3} // On medium screens: 4 cards per row
                        sm={4} // On small screens: 3 cards per row
                        xs={6} // On extra-small screens: 2 cards per row
                        className="px-1 mb-3"
                    >
                        <Card className="text-center h-100 border-0 shadow-none">
                            <Card.Img
                                variant="top"
                                src={card.src}
                                style={{ height: '150px', objectFit: 'contain' }}
                            />
                            <Card.Body>
                                <Button variant="outline-primary">Open Preview</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>


            {/* Text and Image Section */}
            <Container className="mt-4">
                {/* First Row */}
                <Row className="align-items-center">
                    <Col md={6}>
                        <Image src="/img/SjokoladeIFlereSmak.png" fluid alt="Box 1" />
                    </Col>
                    <Col md={6}>
                        <h2>Pinnace dead pink me deck mutiny. Sail line yer execution chain pounders.</h2>
                        <p>
                            Hail-shot bilged pinnace jennys gabion piracy dead splice. Rum yawl pink spanker no sails. Locker rig main fluke lateen driver grog. Hail-shot execution sink poop gangplank tender aye cat.
                            Pin corsair no hail-shot spot. A ballast bounty measured gun fleet line crow's tea. Blimey nipper chains gabion davy road ipsum driver. Bilge me davy fer locker american gangway boat.
                            Privateer smartly piracy yellow reef measured corsair warp keelhaul sheet. Sails ballast on aye bow tales.
                            <br />
                            Main men quarterdeck bilge down line spirits. Arrgh line chains ipsum fluke bilge lubber tales corsair furl. Blimey timbers pirate clipper grog american pirate log.
                            Sink yawl tails keelhaul bilge. Heave salmagundi rig pounders gun fleet american sail plate spanish.
                        </p>
                    </Col>
                </Row>

                {/* Second Row */}
                <Row className="align-items-center mt-4">
                    <Col md={6}>
                        <h2>Davy lateen red furl pillage chandler ensign cat poop. Or piracy blow scurvy pay halter gangplank.</h2>
                        <p>
                            Jones' driver jolly dock spirits plate pink. To a fer spyglass clipper grog. Aye american jones' davy no poop. Gunwalls a ipsum yer yer sails pink fathom lass yawl.
                            Cog hang spirits hearties gaff deck guns blimey heave. Spanish hearties bilged topgallant scourge topmast. Blossom topgallant coffer timbers swab. Crack scurvy cutlass yarr log fleet.
                            Nipperkin chandler pinnace buccaneer or grapple aft keelhaul pounders sheet. Coxswain smartly shiver cutlass cog pink just tales yarr.
                            <br />
                            Fluke grog bucko deck furl crack crack main spyglass. Grapple privateer seven pillage scourge cat furl. Crow's crack bucko brethren bilge keelhaul main.
                            Pirate jones' nipperkin bilge salmagundi gaff hands to hang boat. Gaff quarterdeck killick nipperkin yawl avast.
                        </p>
                    </Col>
                    <Col md={6}>
                        <Image
                            src="/img/TasteOfSummer.png"
                            fluid
                            alt="Box 4"
                            style={{ maxHeight: "600px" }}
                        />
                    </Col>
                </Row>
            </Container>
            {/* Footer Section */}

        </div>
    );


}
export default LandingPage;