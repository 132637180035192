import { useState, useEffect } from 'react';
import { Company } from '../Services/MediastoreMediaApi/models';

const SelectedCompanyLocalStorageKey = 'selected_company_mediastore';

export const useSelectedCompany = () => {
    const [selectedCompany, setSelectedCompany] = useState<Company>(() => {
        const storedValue = localStorage.getItem(SelectedCompanyLocalStorageKey);
        return storedValue ? JSON.parse(storedValue) : { tsKundeID: '', gln: 0, name: '' };
    });

    useEffect(() => {
        if (selectedCompany) {
            localStorage.setItem(SelectedCompanyLocalStorageKey, JSON.stringify(selectedCompany));
        }
    }, [selectedCompany]);

    return { selectedCompany, setSelectedCompany };
};