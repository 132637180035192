import axios from "axios";
import { Company, ImageSetResponse, MediastoreUser } from "./models";

const baseUrl = "https://mediastore.tradesolution.no/media";

const MediastoreMediaApi = {
    imageset: {
        getImageSetByGtin: async (gtin: string) => {    
            const url = `${baseUrl}/imageset/getimagesetsbygtin/${gtin}`;
            console.log("getImageSetByGtin url: " + url);
            return (await axios.get<ImageSetResponse>(url)).data;
        }
    },
    mediastoreUser: {
        get: async (): Promise<MediastoreUser> => {
            const url = `${baseUrl}/user/getmediastoreuser`;
            const response = (await axios.get(url)).data;

            if (response?.accessToCompanies?.length > 0) {
                
                // uppercase company name
                response.accessToCompanies = response.accessToCompanies.map((c: any) => ({ tsCustomerId: c.tsCustomerId, gln: c.gln, name: c.name?.toUpperCase() }));
            }

            return response;
        },
        changeActiveCompany: async (tsKundeId: string, gln: string): Promise<void> => {
            const url = `${baseUrl}/user/changeactivecompany/${tsKundeId}/${gln}`;
            await axios.post(url);
        }
    },
    companies: {
        get: async (): Promise<Company[]> => {
            const url = `${baseUrl}/changeSupplier/getCompanies`;

            // uppercase company name
            return (await axios.get(url)).data.map((c: any) => ({ tsCustomerId: c.tsKundeID, gln: c.gln, name: c.name?.toUpperCase() }));
        },        
    },
};
export default MediastoreMediaApi;