import { Accordion, Alert, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { IcebergButton, IcebergIcon } from "@tradesolution/iceberg-ui-react";
import { useEffect, useState } from "react";
import LogRocket from "logrocket";
import PicassoApi from "../../../../../Services/PicassoApi";
import { AccordionEventKey } from "react-bootstrap/esm/AccordionContext";
import { useSelectedCompanyContext } from "../../../../../Shared/SelectedCompanyContext";

interface Props {
    name: string;
    email: string;
    phone: string;
    isClosed?: boolean;
    onAccordionToggled?: (eventKey: AccordionEventKey) => void;
}

const CustomDesignSection = (props: Props) => {

    const [showModal, setShowModal] = useState(false);
    const { selectedCompany } = useSelectedCompanyContext();
    const handleCloseModal = () =>  {
        setShowModal(false);
        setRequestSent(false);
    }
    const openModal = () => setShowModal(true);
    const [email, setEmail] = useState(props.email);
    const [name, setName] = useState(props.name);
    const [phone, setPhone] = useState(props.phone);

    const [requestSent, setRequestSent] = useState(false);

    useEffect(() => setEmail(props.email), [props.email]);
    useEffect(() => setName(props.name), [props.name]);
    useEffect(() => setPhone(props.phone), [props.phone]);

    const sendRequest = async (): Promise<void> => {
        LogRocket.track('AskForCustomDesign', {
            tsKundeNavn: selectedCompany.name
        });

        await PicassoApi.customDesignRequest.post(
            email,
            name,
            phone,
            selectedCompany.name);

        setRequestSent(true);
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
      };
    
      const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
      };
    
      const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(e.target.value);
      };

    const handleCloseFeedbackAlert = (): void => {
        setRequestSent(false);
    }

    const handleAccordionToggled = (eventKey: AccordionEventKey, e: any): void => {
        if (props.onAccordionToggled) {
            props.onAccordionToggled(eventKey);
        }
    }

    return (
        <>
            <Accordion {...(!props.isClosed ? { defaultActiveKey: "0" } : {})} onSelect={handleAccordionToggled} className='mt-3'>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <IcebergIcon icon="cartridge" className='me-2' /> Tilpass din grafiske profil - Ta kontakt
                    </Accordion.Header>
                    <Accordion.Body>
                        <Card>
                            <Card.Img variant="top" src="/img/CustomDesign.png" />
                            <Card.Body>
                                <Card.Text>
                                    Motta tilbud på skreddersydd design
                                </Card.Text>
                                <IcebergButton onClick={openModal} className="w-100 mt-3" variant="outline-primary">Vis meg mer info</IcebergButton>
                            </Card.Body>
                        </Card>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Innsalgsark tilpasset din grafiske profil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <img src="/img/CustomDesignHeader.png" alt="Custom design" className="img-fluid" />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Card>
                                <Card.Img variant="top" src="/img/CustomDesignPreview1.png" />                                
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src="/img/CustomDesignPreview2.png" />                                
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src="/img/CustomDesignPreview3.png" />                                
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <p>Fyll ut din kontakt informasjon så kontakter vi deg</p>
                            <Row className="mt-3">                               
                                <Col>
                                    <Form.Label>E-post</Form.Label>
                                    <Form.Control 
                                    placeholder="E-post" 
                                    value={email}                                    
                                    onChange={handleEmailChange}/>
                                </Col>                               
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Form.Label>Navn</Form.Label>
                                    <Form.Control 
                                    placeholder="Navn" 
                                    value={name}                                    
                                    onChange={handleNameChange}/>
                                </Col>                                
                                <Col>
                                    <Form.Label>Telefon</Form.Label>
                                    <Form.Control 
                                    placeholder="Telefon" 
                                    value={phone}                                    
                                    onChange={handlePhoneChange}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {requestSent && <Row className="mt-3">
                        <Col>
                            <Alert dismissible variant="success" onClose={handleCloseFeedbackAlert}>
                                <p>
                                    Vi tar kontakt med deg for å finne ut hvordan vi kan hjelpe deg best mulig
                                </p>
                            </Alert>
                        </Col>
                    </Row>}
                </Modal.Body>
                <Modal.Footer>
                    <IcebergButton variant="outline-primary" icon="close" className="col" onClick={handleCloseModal}>
                        Avbryt
                    </IcebergButton>
                    <IcebergButton variant="primary" icon="send" className="col" onClick={sendRequest}>
                        Send
                    </IcebergButton>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CustomDesignSection;