import { useState } from 'react';
import { AppIconName, IcebergIcon, IconName, TjenestegruppeIconName, useLocalStorage } from "@tradesolution/iceberg-ui-react";
import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./index.scss";

export interface SubLink {
    label: string;
    link: string;
    icon?: IconName | TjenestegruppeIconName | AppIconName;
}

export interface NavLink {
    label: string;
    link: string;
    icon?: IconName | TjenestegruppeIconName | AppIconName;
    roles?: string[];
    subLinks?: SubLink[];
}

export interface SidebarProps {
    navLinks: NavLink[];
}

const Sidebar = ({ navLinks }: SidebarProps) => {
    const location = useLocation();
    const [sidebarExpanded, setSidebarExpanded] = useLocalStorage<boolean>("sidebar-expanded", true);
    const sidebarWidth = sidebarExpanded ? "250px" : "55px";
    const [activeSubmenu, setActiveSubmenu] = useState<string>("");

    const handleNavLinkClick = (label: string) => {
        if (!sidebarExpanded) {
            setSidebarExpanded(true);
            setActiveSubmenu(label);
        } else if (activeSubmenu === label) {
            // Toggle active submenu
            setActiveSubmenu("");
        } else {
            setActiveSubmenu(label);
        }
    };


    return (
        <div className="iceberg-navbar-left d-flex flex-column flex-shrink-0 d-none d-xxl-block" style={{ width: sidebarWidth, position: "sticky", top: "58px" }}>
            <ul className="nav flex-column nav-pills mb-auto">
                <li>
                    <a className="nav-link" role="button" onClick={() => setSidebarExpanded(!sidebarExpanded)} style={{ whiteSpace: "nowrap" }}>
                        <IcebergIcon icon={sidebarExpanded ? "chevron-left" : "chevron-right"} />
                        <span style={{ marginLeft: "1rem" }}>{sidebarExpanded ? 'skjul' : 'vis'}</span>
                    </a>
                </li>
                {navLinks.map(navLink => (
                    <div key={navLink.label}>
                        <li>
                            {navLink.subLinks && navLink.subLinks.length > 0 ? (
                                <div className={!sidebarExpanded && navLink.subLinks?.find(o => o.link === location.pathname) ? "nav-link active" : "nav-link"} title={navLink.label} role="button" onClick={() => handleNavLinkClick(navLink.label)}
                                    style={{ whiteSpace: "nowrap" }}>
                                    <IcebergIcon icon={navLink.icon} />
                                    <span style={{ marginLeft: "1rem" }}>{navLink.label}</span>
                                    <IcebergIcon icon={activeSubmenu === navLink.label ? "chevron-up" : "chevron-down"} />
                                </div>
                            ) : (
                                <Nav.Link as={Link} title={navLink.label} to={navLink.link} className={navLink.link === location.pathname ? "active" : ""}>
                                    <IcebergIcon icon={navLink.icon} />
                                    <span style={{ marginLeft: "1rem" }}>{navLink.label}</span>
                                </Nav.Link>
                            )}
                        </li>
                        {navLink.subLinks && activeSubmenu === navLink.label && sidebarExpanded && (
                            <ul className="sub-menu" style={{ listStyleType: 'none' }} >
                                {navLink.subLinks.map(subLink => (
                                    <li key={subLink.label}>
                                        <Nav.Link as={Link} to={subLink.link} className={subLink.link === location.pathname ? "active" : ""}>
                                            <span style={{ marginLeft: "2rem" }}>{subLink.label}</span>
                                        </Nav.Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                ))}
            </ul>
            <ul className="nav flex-column nav-pills">
                <li>
                    <a className="nav-link" href="http://tradesolution.no" style={{ marginBottom: "auto" }}>
                        <IcebergIcon icon="sign" color="#003b6e" />
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default Sidebar;
