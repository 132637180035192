import { useEffect, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap"
import MediastoreMediaApi from "../../../../../Services/MediastoreMediaApi";
import { ImageSetResponse, ProductImage } from "../../../../../Services/MediastoreMediaApi/models";
import { Template } from "..";
import angleDescriptions from "./AngleDescriptions";

interface Props {
  gtin: string;
  onAngleSelected: (angle: string) => void;
  template: Template;
  produktIndex: number;
}

const ProductAngleSelector = (props: Props) => {
  const [imagesetResponse, setImagesetResponse] = useState<ImageSetResponse | null>(null);
  const [selectedImage, setSelectedImage] = useState<ProductImage>();

  const loadImageInfoFromMediastoreMedia = async (gtin: string) => {
    const response = await MediastoreMediaApi.imageset.getImageSetByGtin(gtin);
    if (response &&
      response.length > 0 &&
      response[0].productImages &&
      response[0].productImages.length > 0) {
      setImagesetResponse(response);
      setSelectedImage(response[0].productImages.find((img) => img.isMainImage));
    } else {
      setImagesetResponse(null);
      setSelectedImage(undefined);
    }
  }

  useEffect(() => {
    loadImageInfoFromMediastoreMedia(props.gtin);
  }, [props.gtin]);



  const getImagePath = (templateId: number, produktIndex: number): string => {
    const imageMapping: { [key: number]: string | string[] } = {
      1: "/img/Temp1.png",
      2: [`/img/Temp2.1.png`, `/img/Temp2.2.png`, `/img/Temp2.3.png`],
      3: [
        `/img/Temp3.1.png`,
        `/img/Temp3.2.png`,
        `/img/Temp3.3.png`,
        `/img/Temp3.4.png`,
      ],
      4: [`/img/Temp4.1.png`, `/img/Temp4.2.png`],
      5: [`/img/Temp5.1.png`, `/img/Temp5.2.png`],
      6: [`/img/Temp6.1.png`, `/img/Temp6.2.png`],
      7: [`/img/Temp7.1.png`, `/img/Temp7.2.png`, `/img/Temp7.3.png`],
      8: [`/img/Temp8.1.png`, `/img/Temp8.2.png`, `/img/Temp8.3.png`],
      9: [`/img/Temp9.1.png`, `/img/Temp9.2.png`, `/img/Temp9.3.png`],
      10: [
        `/img/Temp10.1.png`,
        `/img/Temp10.2.png`,
        `/img/Temp10.3.png`,
        `/img/Temp10.4.png`,
      ],
      11: [
        `/img/Temp11.1.png`,
        `/img/Temp11.2.png`,
        `/img/Temp11.3.png`,
        `/img/Temp11.4.png`,
      ],
    };

    const paths = imageMapping[templateId];
    if (Array.isArray(paths)) {
      return paths[produktIndex % paths.length] || "/img/default.png";
    }
    return paths || "/img/default.png";
  };

  const handleAngleSelect = (angle: string) => {
    if (!imagesetResponse) return;

    const imageset = imagesetResponse[0];
    const newImage = imageset.productImages.find((img) => img.angle === angle);

    if (newImage) {
      setSelectedImage(newImage);
      props.onAngleSelected(newImage.angle);
    }
  };

  const getAngleDescription = (angle: string) => {
    const description = angleDescriptions.find((desc) => desc.angle === angle);
    return description ? description.text : angle;
  }


  return (
    <Row className="mt-3">
      <Col md={3} className="me-3">
        <img style={{ maxHeight: "100px", maxWidth: "100px" }} src={getImagePath(props.template.id, props.produktIndex)} alt="Product template" />
      </Col>

      <Col>
        <div className="text-center">
          {/* Template placeholder with Bootstrap styling */}

          <div className="mb-2">
            {!selectedImage &&
              <div className="mt-5">Bilde ble ikke funnet</div>
            }
          </div>

          {/* Custom Dropdown for Angle Selection */}

          {imagesetResponse?.[0]?.productImages && imagesetResponse?.[0]?.productImages?.length > 0 &&
            <Dropdown>
              <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="lg"
                style={{
                  height: "80px",
                  fontSize: "14px",
                  width: "100%",
                  padding: "8px",
                }} >
                {selectedImage ? (
                  <div className="d-flex align-items-center w-100"> {/* Gjør at bilde er på venstre-siden */}

                    <img
                      style={{ maxWidth: "60px", maxHeight: "60px", float: "left" }}
                      className="me-2"
                      src={`https://mediastore.tradesolution.no/download/preview/${selectedImage.previewSmallBlobId}`}
                      alt={selectedImage.angle}
                    />
                    <span className="ms-auto text-end">{selectedImage.angle}</span> {/* Gjør at tekst er på høyre-siden */}
                  </div>
                ) : (
                  "Velg vinkel"
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ width: "290px" }}>
                {imagesetResponse?.[0]?.productImages.map((img) => (
                  <Dropdown.Item
                    style={{ height: "80px" }}
                    key={img.angle}
                    onClick={() => handleAngleSelect(img.angle)}
                    className="d-flex align-items-center"
                  >
                    <img
                      style={{ maxWidth: "60px", maxHeight: "60px" }}
                      src={`https://mediastore.tradesolution.no/download/preview/${img.previewSmallBlobId}`}
                      alt={img.angle}
                    />
                    <span className="ms-auto text-end">{img.angle}</span>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          }
        </div>
      </Col>
    </Row>
  );
};

export default ProductAngleSelector;
