import { useEffect, useState } from "react";
import MediastoreApi from "../../../../Services/MediastoreApi";

interface Props {
    widthInPx: number;
    heightInPx: number;
    gtin: string | null;
    angle: string | null | undefined;
}

const ProductImage = (props: Props) => {
    const widthStr = props.widthInPx.toString() + "px";
    const heightStr = props.heightInPx.toString() + "px";

    const placeHolderImgUrl = `https://placehold.co/${props.widthInPx.toString()}x${props.heightInPx.toString()}`;

    const [image, setImage] = useState<Blob | null>();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>();

    const loadImageFromMediastore = async (gtin: string) => {
        setIsLoading(true);
        try {
            const response = await MediastoreApi.products.getMainImage(gtin);
            setImage(response);
        } catch (error: any) {
            console.error("Error fetching image from MediastoreApi");
            setError("Kunne ikke laste bilde!");
        }
        setIsLoading(false);
    }

    const loadImageAngleFromMediastore = async (gtin: string, angle: string) => {
        setIsLoading(true);
        try {
            const response = await MediastoreApi.products.getAngleImage(gtin, angle);
            setImage(response);
        } catch (error: any) {
            console.error("Error fetching image from MediastoreApi");
            setError("Kunne ikke laste bilde!");
        }
        setIsLoading(false);
    }

    useEffect(() => {
        setError(undefined);
        setIsLoading(false);

        console.log("ProductImage useEffect");
        console.log(props.gtin);
        console.log(props.angle);

        if (props.gtin && props.angle) {
            loadImageAngleFromMediastore(props.gtin, props.angle);
        }
        else if (props.gtin) {
            loadImageFromMediastore(props.gtin);
        } else {
            setImage(null);
        }
    }, [props.gtin, props.angle]);


    return (
        <div style={{ width: widthStr, height: heightStr, display: "flex", alignItems: "center", justifyContent: "center" }}>
            {error && <div>{error}</div>}
            {isLoading && !error && <div>Henter bilde...</div>}
            {!isLoading && !error && <img className="product-image" style={{ maxWidth: widthStr, maxHeight: heightStr }} src={image ? URL.createObjectURL(image) : placeHolderImgUrl} />}
        </div>
    );
}

export default ProductImage;