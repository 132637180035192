import { useEffect, useState } from "react";
import PicassoApi from "../../../../Services/PicassoApi";
import { useSelectedCompanyContext } from "../../../../Shared/SelectedCompanyContext";

interface Props {
    logoFileName: string | undefined;
    logoText: string | undefined;
    fontFamily: string;
    fontColor: string;
    fontSize: number; 
}

const Logo = (props: Props) => { 
    const { selectedCompany } = useSelectedCompanyContext();

    const [logoBlob, setLogoBlob] = useState<Blob | null>(null);

    const getLogo = async (fileName: string) => {
        const logoBlob = await PicassoApi.logos.getLogo(fileName, selectedCompany?.tsCustomerId);
        if(logoBlob){
            setLogoBlob(logoBlob);
        }  
      };

    useEffect(() => {
        if (props.logoFileName) {
            getLogo(props.logoFileName);
        }
        else {
            setLogoBlob(null);
        }
    }, [props.logoFileName]);

    return (
        <div>
            <div className="template-logo-placeholder mt-5">
                {logoBlob && <img id="logo" src={URL.createObjectURL(logoBlob)} style={{ maxWidth: "200px", maxHeight: "100px" }} />}
                {!logoBlob && <h1 style={{ fontFamily: props.fontFamily, color: props.fontColor ?? "#000",  fontSize: props.fontSize}}>{props.logoText}</h1>}
            </div>
        </div>        
    );
}

export default Logo;