import { Accordion, Form } from "react-bootstrap";
import { ContactInfo } from "../../../TemplateData";
import { IcebergIcon } from "@tradesolution/iceberg-ui-react";
import { ChangeEvent } from "react";

interface Props {
    contactInfo: ContactInfo;
    onContactInfoChanged: (contactInfo: ContactInfo) => void;
}

const ContactInfoSection = (props: Props) => {
    const handleNameChanged = (event: ChangeEvent<any>): void => {
        props.onContactInfoChanged({ ...props.contactInfo, name: event.target.value });
    }

    const handleEmailChanged = (event: ChangeEvent<any>): void => {
        props.onContactInfoChanged({ ...props.contactInfo, email: event.target.value });
    }

    const handlePhoneChanged = (event: ChangeEvent<any>): void => {
        props.onContactInfoChanged({ ...props.contactInfo, phone: event.target.value });
        if (event.target.value){
          localStorage.setItem('phoneNumber', event.target.value);
        }
        else{
          localStorage.removeItem('phoneNumber');
        }          
    }

    return (
        <Accordion defaultActiveKey="0" className='mt-3'>
        <Accordion.Item eventKey="0">
          <Accordion.Header><IcebergIcon icon="phone" className='me-2' /> Kontakt info</Accordion.Header>
          <Accordion.Body>
            <Form>
              <Form.Group>
                <Form.Label>Navn</Form.Label>
                <Form.Control type="text" placeholder="Navn" value={props.contactInfo.name} onChange={handleNameChanged} />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>E-post</Form.Label>
                <Form.Control type="email" placeholder="E-post" value={props.contactInfo.email} onChange={handleEmailChanged} />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>Telefon</Form.Label>
                <Form.Control type="phone" placeholder="Telefon" value={props.contactInfo.phone} onChange={handlePhoneChanged} />
              </Form.Group>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
}

export default ContactInfoSection;